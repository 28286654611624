import { makeObservable, observable, action } from "mobx";
import { TokenService } from "../../../services/TokenService";
import AuthService from "../../../services/AuthService/AuthService";
import jwt_decode from "jwt-decode";
import { AppPath } from "../../../routes/routes-enums";
import { AccessControlService } from "../../../utils/access/AccessControlService";

class AuthStore {
  history = {};
  inProcess = false;
  errors = "";

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      inProcess: observable,
      errors: observable,
      history: observable,

      setHistory: action,
      setErrors: action,
      setInProcess: action,
    });
  }

  setHistory = (history) => {
    this.history = history;
  };

  setErrors = (message = "") => {
    this.errors = message;
  };

  setInProcess = (value = false) => {
    this.inProcess = value;
  };

  setTokens = ({ accessToken }) => {
    TokenService.setToken(accessToken);
    //AccessControlService.setToken(accessToken);
  };

  login = async () => {
    this.history.push(AppPath.waitingInspection);
  };

  appendToken = async (data) => {
    try {
      TokenService.setStorage();
      this.setTokens({
        accessToken: data.token,
      });
    } catch (e) {}
  };

  postLogin = async ({ username, password }) => {
    const payload = {
      username: username,
      password: password,
    };

    try {
      this.setErrors();
      this.setInProcess(true);
      const { data } = await AuthService.postLogin(payload);
      await this.appendToken(data);
      const dataUser = await AuthService.getCurrentUser();
      AccessControlService.setToken(dataUser.data);
      this.rootStore.userStore.mergeUserData(dataUser.data);
      await this.login();
    } catch (e) {
      this.setErrors("Неправильный логин или пароль");
    } finally {
      this.setInProcess();
    }
  };

  logout = async (isReload = true) => {
    await this.getLogout();
    await TokenService.removeToken();
    await this.history.push(AppPath.signIn);
    if (isReload) {
      Object.values(this.rootStore).forEach((store) => {
        store.reset && store.reset();
      });
    }
  };

  getLogout = async () => {
    try {
      const { data } = await AuthService.getLogout();
    } catch (e) {
      console.log(e);
    }
  };

  setAuthDataFromStorages = async () => {
    const accessToken = TokenService.getToken();
    if (accessToken) {
      this.setTokens({ accessToken });
    }
  };
}

export default AuthStore;
