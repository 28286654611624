import { Global } from "@mantine/core";
import React from "react";

const GlobalStyles = () => {
  return (
    <Global
      styles={() => ({
        body: {
          color: "rgba(66, 66, 66, 1)",
        },
        '.mantine-Checkbox-label': {
          color: "rgba(66, 66, 66, 1)",
          fontSize: '16px',
        }
      })}
    />
  );
};

export default GlobalStyles;
