import { action, makeObservable, observable } from "mobx";

class UserStore {
  userData = {};
  userEmail = "";

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      userData: observable,
      userEmail: observable,
      setUserData: action,
      setUserEmail: action,
    });
  }

  setUserData = (userData = {}) => {
    console.log(userData);

    this.userData = {
      id: userData.human.id,
      role: userData.human.position,
      firstName: userData.human.firstname,
      middleName: userData.human.patronymic,
      lastName: userData.human.lastname,
    };
  };
  setUserEmail = (email = "") => {
    this.userEmail = email;
  };

  reset = () => {
    this.setUserData();
    this.setUserEmail();
  };

  mergeUserData = (userData) => {
    this.setUserData(userData);
    console.log(userData);
  };
}

export default UserStore;
