import { lazy } from "react";
import { AppPath } from "./routes-enums";
import { Redirect, Route, Switch } from "react-router-dom";
import AuthLayout from "../layouts/AuthLayout";
import React from "react";
import { LayoutPrivate } from "../ui";
import { TokenService } from "../services/TokenService";

export const ROUTES = [
  {
    path: AppPath.authUrl,
    authNew: true,
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "auth" */ "../views/auth")
    ),
  },
  {
    path: AppPath.create,
    authNew: true,
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "auth" */ "../views/create")
    ),
  },
  {
    path: AppPath.waitingInspection,
    exact: true,
    protected: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "waitingInspection" */ "../views/waitingInspection"
      )
    ),
  },
  {
    path: AppPath.waitingInspectionCard,
    exact: true,
    protected: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "waitingInspectionCard" */ "../views/waitingInspection/WaitingInspectionCard"
      )
    ),
  },
  {
    path: AppPath.inspectionLog,
    exact: true,
    protected: true,
    component: lazy(() =>
      import(/* webpackChunkName: "inspectionLog" */ "../views/inspectionLog")
    ),
  },
  {
    path: AppPath.registration,
    exact: true,
    protected: true,
    component: lazy(() =>
      import(/* webpackChunkName: "registration" */ "../views/registration")
    ),
  },
];

const RouteComponent = (route) => {
  if (route.protected && !TokenService.isTokenExists()) {
    return <Redirect to={AppPath.signIn} />;
  }

  if (Array.isArray(route.path) && TokenService.isTokenExists()) {
    return <Redirect to={"/"} />;
  }

  if (Array.isArray(route.path) && route.authNew) {
    return (
      <AuthLayout>
        <Route
          path={route.path}
          exact={route.exact}
          render={(props) => <route.component {...props} />}
        />
      </AuthLayout>
    );
  }

  const RouteCmp = route.component;

  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => (
        <LayoutPrivate>
          <RouteCmp {...props} />
        </LayoutPrivate>
      )}
    />
  );
};

export const RenderRoutes = ({ routes }) => {
  return (
    <Switch>
      <Redirect from={"/"} to={AppPath.waitingInspection} exact />
      {routes.map((route, index) => (
        <RouteComponent key={index + "route"} {...route} />
      ))}
    </Switch>
  );
};
