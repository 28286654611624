import { createStyles, Navbar, UnstyledButton } from "@mantine/core";
import { useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { useSelector } from "../../../../hooks/mobxStoreHooks/useSelector";
import { useEffect } from "react";

const useStyles = createStyles(() => ({
  navbar: {
    backgroundColor: "#b2bbd0",
    paddingBottom: 0,
    border: "none",
    display: "flex",
    justifyContent: "center",
    paddingLeft: 0,
    paddingRight: 0,
  },
  links: {
    height: "100%",
  },
  linkItem: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    fontSize: 16,
    fontWeight: 500,
    color: "#424242",
    transition: "background-color 0.1s linear",
    padding: "35px 0",
    "&:hover": {
      backgroundColor: "#aab2c5",
    },
  },
  active: {
    backgroundColor: "#aab2c5",
  },
}));

const LayoutPrivateNavbar = observer(() => {
  const { classes, cx } = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { menu, setActiveLink, activeLink } = useSelector(
    (store) => store.layoutPrivateStore
  );
  const { userData } = useSelector((store) => store.userStore);

  const handleLinkClick = (item) => {
    setActiveLink(item.id);
    history.push(item.path);
  };

  useEffect(() => {
    let i = menu.find((item) => item.path === location.pathname);
    if (i) {
      setActiveLink(i.id);
    } else {
      setActiveLink(undefined);
    }
  }, [menu]);

  return (
    <Navbar
      width={{ sm: 250, backgroundColor: "rgba(66, 66, 66, 0.02)" }}
      className={classes.navbar}
    >
      <Navbar.Section className={classes.links}>
        {menu.map(
          (item, index) =>
            (
              <UnstyledButton
                key={item.path}
                className={cx(classes.linkItem, {
                  [classes.active]: activeLink === index,
                })}
                onClick={() => handleLinkClick(item)}
              >
                {item.name}
              </UnstyledButton>
            )
        )}
      </Navbar.Section>
    </Navbar>
  );
});

export default LayoutPrivateNavbar;
