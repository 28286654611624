import LayoutPrivateStore from "../ui/layouts/LayoutPrivate/store/LayoutPrivateStore";
import AuthStore from "../views/auth/store/AuthStore";
import UserStore from "./UserStore";

class MedicStore {
  constructor() {
    this.layoutPrivateStore = new LayoutPrivateStore(this);
    this.authStore = new AuthStore(this);
    this.userStore = new UserStore(this);
  }
}

export default MedicStore;
