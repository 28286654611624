import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AppFonts } from "./utils/AppFonts";
import { MantineProvider } from "@mantine/core";
import { appTheme } from "./constants/appTheme";
import MedicStore from "./store/RootStore";
import { Provider } from "mobx-react";
import GlobalStyles from "./GlobalStyles";

export const rootStore = new MedicStore();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider {...rootStore}>
    <MantineProvider withNormalizeCSS withGlobalStyles theme={appTheme}>
      <BrowserRouter>
        <GlobalStyles />
        <App />
        <AppFonts />
      </BrowserRouter>
    </MantineProvider>
  </Provider>
);
