import { AppShell, Box } from "@mantine/core";
import AuthHeader from "./AuthHeader";

const AuthPublic = ({ children }) => {
  return (
    <AppShell
      footer={<></>}
      header={<AuthHeader />}
      padding={16}
      styles={() => ({
        main: { backgroundColor: "#B8C4D4" },
      })}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100%",
        }}
      >
        {children}
      </Box>
    </AppShell>
  );
};

export default AuthPublic;
