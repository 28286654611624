import { Box, Text, Divider } from "@mantine/core";
import { User } from "tabler-icons-react";

import { createStyles } from "@mantine/core";
import { observer } from "mobx-react";
import { useSelector } from "../../../../hooks/mobxStoreHooks/useSelector";

export const useStyles = createStyles(() => ({
  root: {
    display: "flex",
    border: "1px solid rgba(66, 66, 66, 1)",
    borderRadius: 20,
    padding: "10px 20px",
    alignItems: "center",
  },
  user: {
    display: "flex",
    alignItems: "center",
  },
  name: {
    fontWeight: 500,
  },
  role: {
    fontSize: 12,
  },
  exit: {
    textTransform: "uppercase",
    textDecoration: "underline",
    fontWeight: 500,
    cursor: "pointer",
  },
}));

const UserPanel = observer(() => {
  const authStore = useSelector((store) => store.authStore);
  const userStore = useSelector((store) => store.userStore);
  const { logout } = authStore;

  const { classes } = useStyles();

  const handleExit = () => {
    logout();
  };

  const { userData } = userStore;

  console.log(userData);

  return (
    <Box className={classes.root}>
      <Box className={classes.user}>
        <User size={40} />
        <Box ml={10}>
          {userData?.lastName && (
            <Text className={classes.name}>
              {userData?.lastName} {userData?.firstName[0]}.{" "}
              {userData?.middleName[0]}.
            </Text>
          )}
          <Text className={classes.role}>{userData?.role}</Text>
        </Box>
      </Box>
      <Divider orientation="vertical" color={"rgba(66, 66, 66, 1)"} mx={20} />
      <Box ml={10}>
        <Text className={classes.exit} onClick={() => handleExit()}>
          Выйти
        </Text>
      </Box>
    </Box>
  );
});

export default UserPanel;
