import React from "react";
import { AppShell, Box, Loader } from "@mantine/core";
import LayoutPrivateHeader from "./components/LayoutPrivateHeader";
import LayoutPrivateNavbar from "./components/LayoutPrivateNavbar";

const LayoutPrivate = ({ children }) => {
  return (
    <AppShell
      footer={<></>}
      header={<LayoutPrivateHeader />}
      padding={16}
      styles={() => ({
        main: { backgroundColor: "#B8C4D4" },
      })}
      navbar={<LayoutPrivateNavbar />}
    >
      <React.Suspense fallback={<Loader />}>
        <Box
          sx={{
            height:
              "calc(100vh - var(--mantine-header-height, 0rem) - var(--mantine-footer-height, 0rem))",
            overflowY: "auto",
          }}
        >
          {children}
        </Box>
      </React.Suspense>
    </AppShell>
  );
};

export default LayoutPrivate;
