import { Box, Text, Header } from "@mantine/core";
import Logo from "../../../../assets/images/logo.png";
import UserPanel from "./UserPanel";
import { observer } from "mobx-react";
import { useSelector } from "../../../../hooks/mobxStoreHooks/useSelector";

const LayoutPrivateHeader = () => {
  const authStore = useSelector((store) => store.authStore);
  const { logout } = authStore;

  return (
    <Header
      height={90}
      style={{
        backgroundColor: "#59779A",
        padding: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        border: "none",
      }}
    >
      <Box
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <img src={Logo} alt="" width={80} />
        <Box pl={10}>
          <Text
            sx={{
              color: "#424242",
              textShadow: "4px 3px 6px rgba(0,0,0,0.48)",
            }}
          >
            Комплексные
          </Text>
          <Text
            sx={{
              color: "#424242",
              textShadow: "4px 3px 6px rgba(0,0,0,0.48)",
            }}
          >
            Автоматизированные
          </Text>
          <Text
            sx={{
              color: "#424242",
              textShadow: "4px 3px 6px rgba(0,0,0,0.48)",
            }}
          >
            Системы
          </Text>
        </Box>
      </Box>
      <UserPanel />
    </Header>
  );
};

export default LayoutPrivateHeader;
