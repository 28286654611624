import { makeObservable, observable, action } from "mobx";
import { AppPath } from "../../../../routes/routes-enums";
import { roles } from "../../../../constants/roles";

class LayoutPrivateStore {
  sidebarOpen = true;
  activeLink = undefined;
  menu = [
    {
      id: 0,
      name: "Осмотры",
      path: AppPath.waitingInspection,
      permission: [roles.PARAMEDIC],
    },
    // {
    //   id: 1,
    //   name: "Журнал осмотров",
    //   path: AppPath.inspectionLog,
    //   permission: [roles.PARAMEDIC, roles.DIRECTOR, roles.CHIEF_MEDICAL],
    // },
    {
      id: 1,
      name: "Создать сотрудника",
      path: AppPath.create,
    },
    {
      id: 2,
      name: "Создать фельдшера",
      path: AppPath.registration,
    },
  ];

  constructor() {
    makeObservable(this, {
      activeLink: observable,
      menu: observable,
      setActiveLink: action,
    });
  }

  setActiveLink = (value) => {
    this.activeLink = value;
  };
}

export default LayoutPrivateStore;
