import { BREAKPOINTS } from "./breakpoints";

export const appTheme = {
  breakpoints: BREAKPOINTS,
  fontFamily: "Rubik",

  fontSizes: {
    xs: 14,
    sm: 14,
    md: 14,
    lg: 14,
    xl: 14,
  },
  //color: "rgba(66, 66, 66, 1)",
  primaryColor: "orange",
  components: {
    AppShell: {
      defaultProps: {
        fixed: false,
      },
      styles: () => ({
        root: {
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        },
        body: {
          flex: "1 1 0%",
          backgroundColor: "#f8f8f8",
        },
        main: {
          minWidth: 0,
          padding: 0,
        },
      }),
    },
    TextInput: {
      styles: () => ({
        input: {
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          fontSize: 16,
          color: "rgba(66, 66, 66, 1)",
          borderRadius: 8,
          // fontWeight: 400,
        },
        label: {
          marginBottom: 5,
          color: "rgba(66, 66, 66, 1)",
        },
      }),
    },
    Input: {
      styles: () => ({
        input: {
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          fontSize: 14,
          color: "rgba(66, 66, 66, 1)",
          borderRadius: 8,
        },
      }),
    },
    PasswordInput: {
      styles: () => ({
        input: {
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          fontSize: 16,
          color: "rgba(66, 66, 66, 1)",
          borderRadius: 8,
          // fontWeight: 400,
        },
        innerInput: {
          fontSize: 16,
          color: "rgba(66, 66, 66, 1)",
        },
        label: {
          marginBottom: 5,
          color: "rgba(66, 66, 66, 1)",
        },
      }),
    },
    Button: {
      styles: () => ({
        root: {
          borderRadius: 7,
        },
      }),
    },
    Modal: {
      styles: () => ({
        content: {
          borderRadius: 20,
        },
        body: {
          paddingBottom: 40,
        },
      }),
    },
    Select: {
      styles: () => ({
        input: {
          backgroundColor: "#42424299",
          border: "1px solid #424242",
          color: "#FFFFFFB2",
          "&:focus": {
            border: "1px solid #424242",
          },
          "::placeholder": {
            color: "#FFFFFFB2",
          },
        },
        rightSection: {
          "&>svg": {
            "*": {
              fill: "#FFFFFFB2",
            },
          },
        },
        label: {
          marginBottom: 5,
          color: "rgba(66, 66, 66, 1)",
        },
        dropdown: {
          border: "1px solid #424242",
        },
      }),
    },
    DatePickerInput: {
      styles: () => ({
        label: {
          marginBottom: 5,
          color: "rgba(66, 66, 66, 1)",
        },
        input: {
          height: 42,
          fontSize: 16,
        },
        icon: {
          margin: "0 10px",
        },
      }),
    },
    Radio: {
      styles: () => ({
        label: {
          fontWeight: 500,
          color: "rgba(66, 66, 66, 1)",
        },
      }),
    },
  },
};
