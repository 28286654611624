import { createStyles } from "@mantine/core";

export const useStyles = createStyles(() => ({
  root: {
    border: "1px solid rgba(66, 66, 66, 1)",
    padding: "30px 60px",
    borderRadius: 20,
    position: "relative",
    minWidth: 500,
  },
  textTitle: {
    fontSize: 16,
    color: "rgba(66, 66, 66, 1)",
    position: "absolute",
    top: -22,
    backgroundColor: "#B8C4D4",
    padding: 10,
  },
}));
