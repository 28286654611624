import axios from "axios";

const checkConfig = async () => {
  try {
    const data = await axios.get('/network.config.json')
    return data
  } catch (err) {
    console.log('err', err)
  }
}

const data = await checkConfig()
console.log('data', data)

export const url = data.data.IP;

const baseURL = `${url}/api/`;

const baseConfig = {
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
};

export { baseConfig, baseURL };
