import { Global } from "@mantine/core";

import light from "../assets/fonts/Rubik-Light.woff2";
import regular from "../assets/fonts/Rubik-Regular.woff2";
import medium from "../assets/fonts/Rubik-Medium.woff2";
import semiBold from "../assets/fonts/Rubik-SemiBold.woff2";
import bold from "../assets/fonts/Rubik-Bold.woff2";
import extraBold from "../assets/fonts/Rubik-ExtraBold.woff2";

export function AppFonts() {
  return (
    <Global
      styles={[
        {
          "@font-face": {
            fontFamily: "Rubik",
            src: `url('${light}') format("woff2")`,
            fontWeight: 300,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "Rubik",
            src: `url('${regular}') format("woff2")`,
            fontWeight: 400,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "Rubik",
            src: `url('${medium}') format("woff2")`,
            fontWeight: 500,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "Rubik",
            src: `url('${semiBold}') format("woff2")`,
            fontWeight: 600,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "Rubik",
            src: `url('${bold}') format("woff2")`,
            fontWeight: 700,
            fontStyle: "normal",
          },
        },
        {
          "@font-face": {
            fontFamily: "Rubik",
            src: `url('${extraBold}') format("woff2")`,
            fontWeight: 800,
            fontStyle: "normal",
          },
        },
      ]}
    />
  );
}
