import { Box, Text } from "@mantine/core";
import { useStyles } from "./styles";

const BorderBox = ({ children, title }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.root}>
      <Box display={"flex"} sx={{ justifyContent: "center" }}>
        <Text className={classes.textTitle}>{title}</Text>
      </Box>
      {children}
    </Box>
  );
};

export default BorderBox;
