import { Api } from "../apiService";
import { baseConfig } from "../apiConfig";
import { chiefMedic, director, medic } from "../../mock/user";
import { AuthUrl } from "../../routes/routes-enums";

const authInstance = new Api(baseConfig);

const user = (login) => {
  switch (login) {
    case "123":
      return medic;
    case "1234":
      return director;
    case "12345":
      return chiefMedic;
    default:
      return medic;
  }
};

class AuthService {
  static postLogin = async (payload) => {
    return await authInstance.post(AuthUrl.postLogin, payload);

    // await new Promise((r) => setTimeout(r, 2000));

    // console.log(payload);
    // return {
    //   data: {
    //     token: user(payload.username),
    //   },
    // };
  };

  static getCurrentUser = async () => {
    return await authInstance.get(AuthUrl.getCurrentUser);
  };

  static getLogout = async () => {
    //return await authInstance.post(AuthUrl.logout);
  };
}

export default AuthService;
