import { Box, Text } from "@mantine/core";
import Logo from "../../../assets/images/logo.png";

const AuthHeader = () => {
  return (
    <Box
      style={{
        backgroundColor: "#59779A",
        padding: 10,
        display: "flex",
        alignItems: "center",
      }}
    >
      <img src={Logo} alt="" width={80} />
      <Box pl={10}>
        <Text
          sx={{
            color: "#424242",
            textShadow: "4px 3px 6px rgba(0,0,0,0.48)",
          }}
        >
          Комплексные
        </Text>
        <Text
          sx={{ color: "#424242", textShadow: "4px 3px 6px rgba(0,0,0,0.48)" }}
        >
          Автоматизированные
        </Text>
        <Text
          sx={{ color: "#424242", textShadow: "4px 3px 6px rgba(0,0,0,0.48)" }}
        >
          Системы
        </Text>
      </Box>
    </Box>
  );
};

export default AuthHeader;
