import { roles } from "../../constants/roles";
import jwt_decode from "jwt-decode";

export class AccessControlService {
  static userData = {};

  static setToken(data) {
    //console.log(jwt_decode(token));
    AccessControlService.userData = data;
  }

  static isParamedicRole() {
    return (
      !AccessControlService.userData.role ||
      AccessControlService.userData.role === roles.PARAMEDIC
    );
  }
}
